.options {
  display: flex;
  list-style: none;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 520px;
  gap: 8px;
}

.option {
  color: inherit;
  cursor: pointer;
  font-size: 0.7em;
  opacity: 0.7;
  margin-right: 0.5rem;

  a {
    color: inherit;
    text-decoration: none !important;
  }

}

.activeOption {
  composes: option;

  cursor: default;
  opacity: 1;
  font-weight: 600;
}

