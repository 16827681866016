.localeSwitcher {
  margin: 0 auto;
  text-align: center;
}

.footer {
  flex-direction: column;
  font-size: 22px;

  h3 {
    display: inline-block;
    font-size: 24px;
  }

  .copyrightGroup {
    text-align: center;

    .copyright {
      font-size: 20px;
    }
  }

  .contactUs {
    font-size: 16px;
    text-align: center;
  }
}
