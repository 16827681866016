.container {
  margin-top: 32px;
  width: 100%;
}

.banner {
  min-height: 308px;
  border-radius: 32px;
  box-shadow: 0px 0px 0px 10px #ecf2f8;
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    #ffffff 51%,
    hsl(0, 0%, 100%) 100%
  );
  // color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 54px;
  gap: 12px;
  @media (max-width: 1024px) {
    margin-left: 5px;
    margin-right: 5px;
    box-shadow: 0px 0px 0px 4px #ecf2f8;
    padding: 0 16px;
    justify-content: space-around;
  }
  @media (max-width: 767px) {
    flex-wrap: wrap;
    padding: 32px 16px 32px;
    gap: 24px; // Increase gap for better spacing on mobile
  }
  @media (max-width: 479px) {
    background-size: 172% auto;
  }
}

.imgLeft,
.imgRight {
  flex: none;
  align-self: flex-end;
  @media (max-width: 1024px) {
    width: 160px;
  }
  @media (max-width: 767px) {
    max-width: calc(50% - 6px);
    width: auto;
  }
}
.imgCenter {
  max-height: 300px;
  border-radius: 12px;
  max-width: 100%;
  height: auto;
  @media (max-width: 767px) {
    max-height: none;
    width: 100%;
  }
}

.body {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;
  @media (max-width: 767px) {
    padding: 16px 0 0;
    width: 100%;
    order: -1;
  }
}

.name {
  background: -webkit-linear-gradient(-68deg, #5b9dff 6.46%, #b524e8 98.38%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.25;
  @media (max-width: 1024px) {
    font-size: 18px;
  }
}

.title {
  font-weight: 500;
  font-size: 30px;
  line-height: 1.3;
  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 1.4;
  }
  @media (max-width: 479px) {
    font-size: 20px;
  }
  & > span {
    color: #fdfdfd;
    position: relative;
    display: inline-block;
    &:before {
      content: "";
      position: absolute;
      inset: 2px -12px;
      padding: 16px;
      background-color: #72a2ff;
      border-radius: 12px;
      transform: skew(-20deg);
    }
    span {
      position: relative;
      z-index: 1;
    }
  }
  b {
    font-weight: 800;
  }
}

.btn {

  margin-top: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 0 20px 0 calc(20px + 0.1em);
  height: 54px;
  width: 320px;
  font-size: 20px;
  // letter-spacing: 1px;
  border-radius: 40px;
  overflow: hidden;
  will-change: transform;
  font-weight: 700;
  color: inherit;
  box-shadow: 0px 0px 60px 0px #4878f0a3;
  @media (max-width: 1024px) {
    margin-top: 8px;
    font-size: 16px;
  }
  &:before {
    content: "";
    position: absolute;
    inset: 0 -100% 0 0;
    background: linear-gradient(103.32deg, #5162ff, #4569ed, #3d4ff7);
    transition: transform 0.4s;
  }
  span {
    color: #fff;
    position: relative;
    z-index: 1;
  }
  &:hover {
    &:before {
      transform: translateX(-50%);
    }
  }
}
